
























import { Component, Vue, Prop } from 'vue-property-decorator';
import { NewsItem } from '@/models/NewsItem';

@Component
export default class CampaignDetailHeader extends Vue {
  @Prop({ required: true })
  readonly news!: NewsItem;

  @Prop({ required: true })
  readonly permitCardTypeText!: string;

  get isNew() {
    return this.news ? this.news.isNew : false;
  }
}
