




































import { Component, Vue } from 'vue-property-decorator';
import ContactPanel from '@/components/ContactPanel.vue';
import NewsDetailHeader from '@/components/News/NewsDetailHeader.vue';
import { OpCardList, OpCard } from '@/models/opcards/OpCard';
import { NewsRepository } from '@/repositories/NewsRepository';
import { NewsItem } from '@/models/NewsItem';
import * as cmsUtils from '@/common/cmsUtils';

@Component({
  components: {
    ContactPanel,
    NewsDetailHeader
  }
})
export default class P0906 extends Vue {
  news = {} as NewsItem;
  showPageFlag = false;

  created() {
    this.onLoad();
  }

  onLoad() {
    if (!this.$route.params.id) {
      return;
    }
    this.fetchNews();
  }

  get cardType() {
    return this.$store.state.microCMS.cardType;
  }

  get mainOpCard() {
    if (!this.$auth.isAuthenticated) {
      return {} as OpCard;
    }
    return OpCardList.valueOf(
      this.$auth.user['https://one-odakyu.com/op_cards']
    ).selectMainCard;
  }

  get newsRepository() {
    return new NewsRepository(
      this.$store.state.isSP,
      this.$store.state.supportsWebP
    );
  }

  async fetchNews() {
    const item = await this.newsRepository.select(this.$route.params.id);
    if (!item) {
      return this.$router.replace('/notfound');
    }
    this.news = item;

    if (!this.isPermitCardType) {
      return this.$router.replace('/notfound');
    }
    this.showPageFlag = true;
  }

  get isPermitCardType() {
    if (this.news.isPermitTargetAll) {
      return true;
    }
    if (!this.$auth.isAuthenticated) {
      return false;
    }
    if (this.news.isPermitTargetGroup) {
      return cmsUtils.isIncludedGroupCardTypes(
        this.mainOpCard.odakyuCustomerNo,
        this.news.permitCardTypeGroups,
        this.cardType
      );
    }
    if (this.news.isPermitTargetSingle) {
      return cmsUtils.isIncludedCardTypes(
        this.mainOpCard.odakyuCustomerNo,
        this.news.permitCardTypes
      );
    }
    return false;
  }

  get permitCardTypeText() {
    if (this.news.isPermitTargetAll) {
      return '全券種';
    }
    if (this.news.permitCardTypeText) {
      return this.news.permitCardTypeText;
    }
    if (this.news.isPermitTargetGroup) {
      return this.news.permitCardTypeGroups.map(g => g.name).join('、');
    }
    if (this.news.isPermitTargetSingle) {
      return this.news.permitCardTypes.map(c => c.name).join('、');
    }
    return '';
  }
}
